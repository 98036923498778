import { useCallback, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, Button, Typography } from "antd";

import {
  editVehicle,
  getClients,
  getDropdownUsers, initializeZones,
  setModalName,
  setModels,
} from "store/actions";
import {parseClients, parseModels, parseUsers, parseZoneOptions} from "utils/parsers";
import { VEHICLE_FORMIK } from "utils/formik-data";
import { FormItem, SelectSearch } from "components";
import { AppState } from "store";
import { VehicleValues } from "types/formik";
import { getFullName } from "utils/helpers";
import {VehicleServiceStatus} from "../protobuf/vehicle_service_pb";
import {VEHICLE_SERVICE_STATUS} from "../utils/mapped-data";

const { Title, Text } = Typography;

export default function EditVehiclesForm() {
  const dispatch = useDispatch();
  const {
    vehicles: { models, clients, selectedVehicle },
    users: { dropdownUsers },
    zones: {zones},
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);

  const modelsOptions = useMemo(() => parseModels(models), [models]);
  const usersOptions = useMemo(() => parseUsers(dropdownUsers), [dropdownUsers]);
  const clientOptions = useMemo(() => parseClients(clients), [clients]);
  const zonesOptions = useMemo(() => parseZoneOptions(zones), [zones]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: VehicleValues) => {
      dispatch(
        editVehicle({
          id: selectedVehicle?.id || "",
          licensePlate: values.vehicle_id.trim(),
          modelId: values.vehicle_model,
          ownerOrgId: values?.client || "",
          currentlyAssignedToArrayList: values.assign_to,
          locationName: values.location?.trim(),
          deviceIotId: values.iot_id,
          zoneId: values.zone,
            vehicleServiceStatus: values.vehicle_service_status,
        })
      );
      onCancel();
    },
    [dispatch, onCancel, selectedVehicle?.id]
  );
  const vehicleServiceOption = Object.keys(VehicleServiceStatus.Enum).map((key, index) => ({
    label: VEHICLE_SERVICE_STATUS[index as VehicleServiceStatus.Enum],
    value: index,
  }))


  const initialValues: VehicleValues = useMemo(() => {
    return {
      vehicle_id: selectedVehicle?.licensePlate || "",
      vehicle_model: selectedVehicle?.modelId || "",
      client: selectedVehicle?.clientOrgId || "",
      assign_to: selectedVehicle?.assignee?.map((u) => u.id) || [],
      zone: selectedVehicle?.zoneId || "",
      location: selectedVehicle?.locationName || "",
      iot_id: selectedVehicle?.deviceIotId || "",
        vin:selectedVehicle?.vin.trim() || "",
        vehicle_service_status: selectedVehicle?.vehicleServiceStatus !== undefined 
        ? selectedVehicle?.vehicleServiceStatus 
        : 0,
    };
  }, [selectedVehicle]);

  useEffect(() => {
    dispatch(setModels());
    dispatch(getClients());
    dispatch(initializeZones())
    dispatch(getDropdownUsers())
  }, [dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VEHICLE_FORMIK.validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({
        values,
        errors,
        isValid,
        dirty,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => (
        <div className="form-container">
          <Title level={5}>Edit Vehicle</Title>
          <Text className="description" type="secondary">
            Modify the information below and hit save to update
          </Text>
          <Form onFinish={handleSubmit} className="--add-vehicles-form">
            <FormItem label="Vehicle ID" required error={errors.vehicle_id}>
              <Input
                name="vehicle_id"
                value={values.vehicle_id}
                onChange={handleChange}
                placeholder="Vehicle ID"
                disabled={userType == 1 || userType == 2 ? false : true}
              />
            </FormItem>
            <FormItem
              label="Model"
              error={errors.vehicle_model}
              required>
              <SelectSearch
                value={values.vehicle_model}
                items={modelsOptions}
                setFieldValue={setFieldValue}
                fieldName="vehicle_model"
                placeholder="Select"
                disabled={true}
              />
            </FormItem>
              {userType > 2 && <FormItem error={errors.assign_to as string} label="Assignee">
              <SelectSearch
                value={values.assign_to}
                items={usersOptions}
                setFieldValue={setFieldValue}
                fieldName="assign_to"
                placeholder="Select User"
                isMultiple
              />
            </FormItem> }
              {userType <=2 && <FormItem label="Device ID" error={errors.iot_id}>
              <Input
                name="iot_id"
                value={values.iot_id}
                onChange={handleChange}
                placeholder="000000000000000"
              />
            </FormItem> }

            <FormItem error={errors.zone} label="Zone">
              <SelectSearch
                value={values?.zone || ""}
                items={zonesOptions}
                setFieldValue={setFieldValue}
                fieldName="zone"
                placeholder="Select "
              />
            </FormItem>
              {userType <= 2 && <FormItem error={errors.client} label="Client">
              <SelectSearch
                value={values?.client || ""}
                items={clientOptions}
                setFieldValue={setFieldValue}
                fieldName="client"
                placeholder="Select "
              />
            </FormItem> }
            {/*<FormItem label="Rider’s Pickup Location" error={errors.location}>*/}
            {/*  <Input*/}
            {/*    name="location"*/}
            {/*    value={values.location}*/}
            {/*    onChange={handleChange}*/}
            {/*    placeholder="Linked to Vehicle"*/}
            {/*  />*/}
            {/*</FormItem>*/}
            <FormItem error={errors.vehicle_service_status as string} label="Service Status">
              <SelectSearch
                value={values.vehicle_service_status === 0 ? 0 : values.vehicle_service_status || 0}  
                items={vehicleServiceOption}
                setFieldValue={setFieldValue}
                fieldName="vehicle_service_status"
                placeholder="Select"
              />
            </FormItem>
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid && dirty}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
