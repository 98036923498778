import { useCallback, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

import { actionMenu } from "svgs";
import {BatteryState} from "types/store";
import {getBatteryDetail, getVehicle, setModalName, setSelectedBattery} from "../../../store/actions";
import {initializeBatteriesHeartbeat} from "../../../store/actions/battery_hb_actions";


export default function DropdownAction({
  battery,
}: {
    battery: BatteryState["selectedBattery"];
}) {
  const dispatch = useDispatch();
    console.log("bat in del : ", battery)
    const onDelete = useCallback(() => {

        dispatch(setSelectedBattery(battery));
        dispatch(setModalName("delete_battery"));
    }, [dispatch]);

    const onBatteryDetail = useCallback( () => {
        dispatch(setSelectedBattery(battery));
        //TODO: uncomment when data found
        // dispatch(getBatteryDetail({id: battery?.id || '1c2c4205-384c-4c95-bb07-99c81d213c7'}))
        // dispatch(initializeBatteriesHeartbeat({id: battery?.id || ""}))
    }, [dispatch]);


  const menu = useMemo(
    () => (
      <Menu>
          <Menu.Item key="1">
          </Menu.Item>

          <Menu.Item key="1">
              <Link
                  to={ROUTES.battery_detail}
                  onClick={onBatteryDetail}
              >
                  Battery Detail
              </Link>
          </Menu.Item>
          <Menu.Item key="3" className="delete-action" onClick={onDelete}>
              Archive
          </Menu.Item>

      </Menu>
    ),
    [onDelete, onBatteryDetail]
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link">{actionMenu}</a>
    </Dropdown>
  );
}
