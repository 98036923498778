import { Menu, Layout } from "antd";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AppState } from "store";

import {
  batteryIcon1,
  clientsIcon,
  dashboardIcon,
  financialsIcon,
  issuesIcon,
  nextStepIcon,
  settingsIcon,
  usersIcon,
  vehiclesIcon,
  zonesIcon,
  batteryIcon,
  batterySwapIcon,
  batteryFullIcon,
  vehiclesModelIcon,
} from "svgs";
import { ROUTES } from "utils/constants";

const { Sider } = Layout;

interface ComponentProps {
  collapsed: boolean;
}

function getIconSpan(icon: JSX.Element) {
  return <span className="icon-span">{icon}</span>;
}

const navList = [
  {
    route: ROUTES.dashboard,
    label: "Dashboard",
    icon: getIconSpan(dashboardIcon),
  },
  { route: ROUTES.users, label: "Users", icon: getIconSpan(usersIcon) },
  {
    route: ROUTES.vehicles,
    label: "Vehicles",
    icon: getIconSpan(vehiclesIcon),
  },
  // {
  //   route: ROUTES.vehicle_model,
  //   label: "Vehicle Models",
  //   icon: getIconSpan(vehiclesModelIcon),
  // },
  {
    route: ROUTES.battery,
    label: "Batteries",
    icon: getIconSpan(batteryIcon),
  },
  { route: ROUTES.kiosks, label: "Kiosks", icon: getIconSpan(batterySwapIcon) },
  
  // {
  //   route: ROUTES.financials,
  //   label: "Financials",
  //   icon: getIconSpan(financialsIcon),
  // },
  { route: ROUTES.zones, label: "Zones", icon: getIconSpan(zonesIcon) },
  { route: ROUTES.issues, label: "Issues", icon: getIconSpan(issuesIcon) },
  { route: ROUTES.clients, label: "Organizations", icon: getIconSpan(clientsIcon) },
  // {
  //   route: ROUTES.next_step,
  //   label: "Next Steps",
  //   icon: getIconSpan(nextStepIcon),
  // },
  // {
  //   route: ROUTES.settings,
  //   label: "Settings",
  //   icon: getIconSpan(settingsIcon),
  // },
];

function getRoleBasedNavList(userType: number) {
  return userType === 3 || userType == 5 || userType == 4
    ? navList.filter(
        (navOption) => 
          navOption.label !== "Batteries" && navOption.label !== "Organizations" && navOption.label !== "Vehicle Models" && navOption.label !== "Kiosks"
      )
    : userType == 6
          ? navList.filter(
              (navOption) =>
                navOption.label !== "Batteries" && navOption.label !== "Organizations" && navOption.label !== "Vehicle Models" && navOption.label !== "Zones"
          )
          :userType == 2
          ? navList.filter(
              (navOption) =>
                  navOption.label !== "Dashboard"
          ): navList;
}

export default function AppNav(props: ComponentProps) {
  const { pathname } = useLocation();
  const { collapsed } = props;
  const selectedTab = navList.find((tab) => {
    if (tab.route === "/zones") {
      return (
        pathname.includes(tab.route) ||
        pathname.includes("/add-zone") ||
        pathname.includes("/edit-zone")
      );
    }
    if (tab.route === "/vehicles") {
      return (
        pathname.includes(tab.route) ||
        pathname.includes("/vehicle_model") 
      );
    }
    return pathname.includes(tab.route);
  });
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);

  const history = useHistory();
  const onNavClick = useCallback(
    ({ key }) => {
      const clickedTab = navList.find((tab) => tab.label === key);
      if (clickedTab) {
        history.push(clickedTab.route);
      }
    },
    [history]
  );

  return (
    <Sider
      theme="light"
      width={175}
      className="app-nav"
      trigger={null}
      collapsible
      collapsedWidth={66}
      collapsed={collapsed}
    >
      <div className="logo" />
      <Menu
        className="nav-items"
        theme="light"
        mode="inline"
        selectedKeys={[selectedTab?.label || "vehicles"]}
        onClick={onNavClick}
      >
        {getRoleBasedNavList(userType).map((item) => (
          <Menu.Item className="__item" key={item.label} icon={item.icon}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
}
